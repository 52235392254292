import React from "react"

import BaseLayout, { BaseLayoutProps } from "./base"
import HeaderDoktor from "../Header/HeaderDoktor"
import { NoIndex } from "../NoIndex"

export interface SecondaryPageLayoutProps extends BaseLayoutProps {
  phones?: string[]
}

export default function SecondaryPageLayout({
  children,
  title,
  description,
  phones = ["8 800 700-66-96"],
}: SecondaryPageLayoutProps) {
  return (
    <BaseLayout title={title} description={description}>
      <NoIndex />
      <HeaderDoktor phones={phones} withButton={false} />
      {children}
    </BaseLayout>
  )
}
