import React from "react"

import Box from "@material-ui/core/Box"
import Container from "@ecom/ui/components/Container"
import PageStatus from "@ecom/ui/components/PageStatus"
import SecondaryPageLayout from "../components/Layouts/secondaryPage"

export default function SuccessPage() {
  return (
    <SecondaryPageLayout title="Успех">
      <section className="form-outer">
        <Container>
          <Box display="flex" justifyContent="center" alignItems="center" height="50vh">
            <PageStatus success />
          </Box>
        </Container>
      </section>
    </SecondaryPageLayout>
  )
}
